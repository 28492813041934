<template>
    <el-card class="box-card doc-card" :class="getDocStatus(1)" shadow="hover">
        <div class="header" @click="toggleDropDown">
            <span>{{ docName }}</span>
            <i class="el-icon-arrow-down dropdown-arrow" :class="{ rotate180: isCollapsed }"></i>
        </div>
        <transition name="fade">
            <div class="body" v-show="isCollapsed">
                <div class="info justify">
                    <div class="status">
                        <span>Status: </span>
                        <el-tag size="medium" :type="getDocStatus(0)">{{ getDocStatus(2) }}</el-tag>
                    </div>
                    <div class="generate-date" v-if="data.type == null">
                        <span>{{ $t('account.docs.docs-29') }}: </span>
                        <span
                            ><strong>{{
                                $moment(data.generated ? data.generated.emitted_date : data.doc_date).format('DD-MM-Y')
                            }}</strong></span
                        >
                    </div>
                    <div class="generate-date" v-else-if="data.type != null && data.type === 'info'">
                        <span>{{ $t('account.docs.docs-30') }}: </span>
                        <span
                            ><strong>{{ data.status }}</strong></span
                        >
                    </div>
                </div>
                <div class="buttons-1 justify" v-if="data.type == null">
                    <el-button
                        :class="{ 'full-width': isReported || isSigned || isWaiting || isCanceled }"
                        icon="el-icon-download"
                        @click="downloadUnsignedDoc"
                        size="medium"
                        >{{ $t('account.docs.docs-31') }}
                    </el-button>
                    <el-button
                        size="medium"
                        type="danger"
                        :disabled="isFictive"
                        v-if="!isReported && !isSigned && !isWaiting && !isCanceled"
                        icon="el-icon-warning"
                        @click="$emit('reportDoc', data.adid)"
                        >{{ $t('account.docs.docs-32') }}</el-button
                    >
                </div>
                <div class="divider"></div>
                <div class="upload-file" v-if="data.type == null">
                    <template v-if="hasFile">
                        <div class="has-file-title">{{ $t('account.docs.docs-33') }}</div>
                        <div class="file justify">
                            <span><i class="el-icon-document"></i> {{ docName }}.{{ fileExtension }}</span>
                            <el-button icon="el-icon-download" type="success" size="mini" @click="downloadUploadedFile">
                            </el-button>
                        </div>
                    </template>
                    <template v-else-if="!isReported && !isWaiting && !isSigned && !isCanceled">
                        <div class="has-file-title" v-if="data.esign && !isExternal">
                            <span v-if="data.esign_required">
                                {{ $t('account.docs.docs-34') }}
                            </span>
                            <span v-else>
                                {{ $t('account.docs.docs-35') }}
                            </span>
                        </div>
                        <div class="has-file-title" v-else>{{ $t('account.docs.docs-36') }}</div>
                        <div class="has-file-subtitle">
                            <span v-if="data.esign && data.esign_required && !isExternal">
                                {{ $t('account.docs.docs-37') }}
                            </span>
                            <span v-else>
                                {{ $t('account.docs.docs-38') }}
                            </span>
                        </div>
                        <el-upload
                            :name="`docfile-${data.adid}`"
                            accept="image/*,application/pdf"
                            class="file-upload"
                            :ref="`upload-${data.adid}`"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :auto-upload="false"
                        >
                            <el-button slot="trigger" class="full-width" size="medium" icon="el-icon-upload">{{
                                $t('account.docs.docs-39')
                            }}</el-button>
                        </el-upload>
                    </template>
                    <template v-else-if="!isReported && !hasFile && isWaiting && !isCanceled">
                        <div class="waiting-but-no-file">
                            {{ $t('account.docs.docs-40') }}
                        </div>
                        <el-button class="full-width" size="medium">{{ $t('account.docs.docs-41') }}</el-button>
                    </template>
                    <template v-else-if="isSigned && isPhisicalSigned">
                        <div class="waiting-upload">
                            {{ $t('account.docs.docs-42') }}
                        </div>
                    </template>
                </div>
                <div class="buttons-2 justify" v-else-if="data.type != null && data.type === 'info'">
                    <a :href="data.link" target="_blank"
                        ><el-button icon="el-icon-link" size="medium">{{ $t('account.docs.docs-43') }} </el-button></a
                    >
                </div>
                <div class="reported-reason" v-if="isReported">
                    {{ $t('account.docs.docs-44') }}: <strong>{{ data.generated.reported }}</strong>
                </div>
                <div class="reported-reason" v-if="isRejected">
                    {{ $t('account.docs.docs-45') }}: <strong>{{ data.generated.reject_reason }}</strong>
                </div>
            </div>
        </transition>
    </el-card>
</template>

<script>
import { uploadDocFile, downloadUnsignedDoc, downloadUploadedFile } from '@/api/cont';
import { mapGetters } from 'vuex';
export default {
    name: 'doc',
    props: ['data'],
    data() {
        return {
            isCollapsed: false,
        };
    },
    methods: {
        downloadUnsignedDoc() {
            const params = {
                id: this.data.adid,
            };
            if (this.isFictive) params.docId = this.data.id;
            downloadUnsignedDoc(params).then((res) => {
                if (typeof res.message.data !== 'undefined' && res.message.data !== '') {
                    const linkSource = `data:application/pdf;base64,${res.message.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = res.message.name + '.pdf';

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();

                    if (this.data.adid == 0) this.$emit('fetchDocs');

                    return false;
                }
            });
        },
        downloadUploadedFile() {
            const params = {
                id: this.data.generated.id,
                file_id: this.data.generated.file_id,
            };
            downloadUploadedFile(params).then((res) => {
                if (typeof res.message.data !== 'undefined' && res.message.data !== '') {
                    let dataType = 'application/pdf';
                    let ext = 'pdf';

                    if (typeof res.message.type == 'string' && res.message.type.length) {
                        dataType = res.message.type;
                    }

                    if (typeof res.message.extension == 'string' && res.message.extension.length) {
                        ext = res.message.extension;
                    }

                    const linkSource = `data:` + dataType + `;base64,${res.message.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = this.data.name + '.' + ext;

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                    return false;
                }
            });
        },
        getDocStatus(returnCase = 1) {
            switch (true) {
                case this.data.type != null && this.data.type === 'info':
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed info'
                            : 'info'
                        : returnCase === 0
                        ? 'success'
                        : this.$t('account.docs.docs-46');

                    break;
                case this.isGenerated && parseInt(this.data.generated.status) === 1:
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed signed'
                            : 'signed'
                        : returnCase === 0
                        ? 'success'
                        : this.$t('account.docs.docs-47');
                    break;
                case this.isGenerated && parseInt(this.data.generated.status) === -1:
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed canceled'
                            : 'canceled'
                        : returnCase === 0
                        ? 'canceled'
                        : this.$t('account.docs.docs-48');
                    break;
                case this.isGenerated && this.data.generated.reported !== '':
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed reported'
                            : 'reported'
                        : returnCase === 0
                        ? 'warn'
                        : this.$t('account.docs.docs-49');
                    break;

                case this.isGenerated && parseInt(this.data.generated.status) === 2:
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed waiting'
                            : 'waiting'
                        : returnCase === 0
                        ? 'primary'
                        : this.$t('account.docs.docs-50');
                    break;

                case this.isGenerated &&
                    parseInt(this.data.generated.status) === 0 &&
                    this.data.generated.reject_reason != null:
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed rejected'
                            : 'rejected'
                        : returnCase === 0
                        ? 'danger'
                        : this.$t('account.docs.docs-51');
                    break;

                default:
                    return returnCase === 1
                        ? !this.isCollapsed
                            ? 'not-collapsed unsigned'
                            : 'unsigned'
                        : returnCase === 0
                        ? 'info'
                        : this.$t('account.docs.docs-52');
            }
        },
        uploadFile() {
            const file = document.querySelector(`input[name="docfile-${this.data.adid}"]`).files;
            if (file.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('account.docs.docs-53'),
                });
                return false;
            }
            this.$confirm(this.$t('account.docs.docs-54'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning',
            })
                .then(async () => {
                    const formData = new FormData();
                    formData.append('file', file[0]);
                    formData.append('docid', this.data.id);
                    formData.append('aid', this.data.adid);

                    const res = await uploadDocFile(formData);
                    if (typeof res.message === 'undefined') return false;

                    const response = res.message;

                    switch (true) {
                        case response?.control_expired === true:
                            this.sbMsg({
                                type: 'error',
                                message: this.$t('account.docs.docs-58'),
                            });
                            break;

                        case response?.issuer_missing === true:
                            this.sbMsg({
                                type: 'error',
                                message: this.$t('account.docs.docs-59'),
                            });
                            break;

                        case response?.certificate_missing === true:
                            this.sbMsg({
                                type: 'error',
                                message: this.$t('account.docs.docs-60'),
                            });
                            break;

                        case response?.approved === true:
                            this.sbMsg({
                                type: 'success',
                                message: this.$t('account.docs.docs-55'),
                            });
                            break;

                        case response?.success === true && response?.approved === false:
                            this.sbMsg({
                                type: 'success',
                                message: this.$t('account.docs.docs-56'),
                            });

                        default:
                            this.sbMsg({
                                type: 'error',
                                message: this.$t('account.docs.docs-57'),
                            });
                    }

                    this.$emit('fileUpload');
                    this.removeListener();
                })
                .catch((err) => {});
        },
        removeListener() {
            const file = document.querySelector(`input[name="docfile-${this.data.adid}"]`);
            if (file != null) {
                file.removeEventListener('change', () => {});
            }
        },
        addListener() {
            const file = document.querySelector(`input[name="docfile-${this.data.adid}"]`);

            if (file != null) {
                file.addEventListener('change', this.uploadFile);
            }
        },
        toggleDropDown() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
    computed: {
        ...mapGetters({
            isExternal: 'user/isExternal',
        }),
        isFictive() {
            return this.data.adid === 0;
        },
        isGenerated() {
            return typeof this.data.generated !== 'undefined' && this.data.generated !== false;
        },
        isSigned() {
            return this.isGenerated && parseInt(this.data.generated.status) === 1;
        },
        docName() {
            return this.isGenerated && this.data.generated.custom_name !== ''
                ? this.data.generated.custom_name
                : this.data.name;
        },
        isReported() {
            return this.isGenerated && this.data.generated.reported !== '';
        },
        isRejected() {
            return (
                this.isGenerated &&
                !this.isSigned &&
                !this.isWaiting &&
                !this.isCanceled &&
                this.data.generated.reject_reason != null
            );
        },
        hasFile() {
            return this.isGenerated && this.data.generated.file_exists;
        },
        isWaiting() {
            return this.isGenerated && parseInt(this.data.generated.status) === 2;
        },
        isCanceled() {
            return this.isGenerated && parseInt(this.data.generated.status) === -1;
        },
        isPhisicalSigned() {
            return (
                this.isGenerated &&
                typeof this.data.generated.semnat_fizic !== 'undefined' &&
                parseInt(this.data.generated.semnat_fizic) === 1
            );
        },
        fileExtension() {
            return this.isGenerated &&
                typeof this.data.generated.file_id !== 'undefined' &&
                this.data.generated.file_id !== '' &&
                this.data.generated.file_id !== 'null'
                ? this.data.generated.file_id.split(/[.]+/).pop()
                : 'pdf';
        },
    },
    watch: {},
    mounted() {
        this.addListener();
    },
    beforeDestroy() {
        this.removeListener();
    },
};
</script>
<style lang="scss"></style>
